const CLIPPED_ELEMENT_ID = 'clipped';
const element = document.getElementById(CLIPPED_ELEMENT_ID);
const maxDeviceAngles = 10;
const deviceAngles = [];
let deviceAngle = 56;
let acceleration, hasGyro;
//gets reduced motion query object.
var reducedMotionQuery = window.matchMedia('(prefers-reduced-motion)');

// let mousePos = {x: 0, y: 0};

const { isiOS, hide, show } = require('./utils');
//degrees to radians
function degToRad(degrees) {
  return (degrees * Math.PI) / 180;
}
//radians to degrees
function radToDeg(radians) {
  return (radians * 180) / Math.PI;
}
//Deals with getting the propper angle out of devicemotion event
function handleMotion(event) {

  acceleration = event.accelerationIncludingGravity;
  hasGyro = acceleration.x || acceleration.y || acceleration.z;
  //check for device motion support or reduce motion query
  if (!hasGyro || reducedMotionQuery.matches) {
    return;
  }

  let gravityAngle = (Math.atan2(acceleration.x, acceleration.y) % (2 * Math.PI)) + Math.PI;

  gravityAngle = (360 - radToDeg(gravityAngle)) % 360;

  deviceAngles.push(gravityAngle);

  if (deviceAngles.length > maxDeviceAngles) {
    deviceAngles.shift();
  }

  let sumX = 0;
  let sumY = 0;

  for (let ctr = 0; ctr < deviceAngles.length; ctr += 1) {
    sumX += Math.cos(degToRad(deviceAngles[ctr]));
    sumY += Math.sin(degToRad(deviceAngles[ctr]));
  }

  sumX /= deviceAngles.length;
  sumY /= deviceAngles.length;
  gravityAngle = (radToDeg(Math.atan2(sumY, sumX)) + 360) % 360;

  deviceAngle = gravityAngle;
  if (hasGyro) {
    setMaskAngle(-(deviceAngle+90));
  }
}

// Add listeners, handleMotion will determine if the device can handle motion and call the manual animation function if necesary
window.addEventListener('devicemotion', handleMotion, true);

// No landscape
window.onorientationchange = function reorient(e) {

    var rotationLockElementID = "#rotation-lock"
    var portrait = (window.orientation % 180 == 0);
    if (portrait) {
      hide(rotationLockElementID);
      $(".positive").attr('id', 'clipped')
    } else {
      show(rotationLockElementID)
      $(".positive").attr('id', '')
    }
};

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  var angleInRadians = (angleInDegrees-90) * Math.PI / 180.0;

  return {
    x: centerX + (radius * Math.cos(angleInRadians)),
    y: centerY + (radius * Math.sin(angleInRadians))
  };
}
//Creates the semi-circle css
function describeArc(x, y, radius, startAngle, endAngle){

    var start = polarToCartesian(x, y, radius, endAngle);
    var end = polarToCartesian(x, y, radius, startAngle);

    var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    var d = [
        "M", start.x, start.y, 
        "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(" ");

    return d;       
}
// Sets the actual angle(rotation) of the semi-circle
function setMaskAngle(angle) {
  let sa1 = angle;
  let ea1 = (sa1 + 180 > 360) ? sa1 - 180 : sa1 + 180;
  let size = (window.innerWidth > window.innerHeight) ? window.innerWidth : window.innerHeight
  document.getElementById("arc1").setAttribute("d", describeArc(window.innerWidth / 2, window.innerHeight / 2, size, sa1, ea1));
}

// Fire off our static rotation animation when gyro isn't available
var animationFinished = false
$(document).ready(()=>{
  // Chrome sometimes sends two user agents so we'll check for both
  if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
    $(".clipper").css("height", "100%");
     $(".clipper").css("width", "100%");
  }
  //check for device motion support or reduce motion query
  if (!hasGyro || reducedMotionQuery.matches) {
    setMaskAngle(0);
    var delay = 100;
    setTimeout(()=>{
      springAnimationHack(90, 230, 6000, (newvalue)=>{if (!hasGyro) {setMaskAngle(newvalue)}});
    }, delay)
  }
})

function customEaseOutElastic(t) {
    var p = 0.2;
    return Math.pow(2, -10*t) * Math.sin((t-p/4) * (2*Math.PI)/p) + 1;
}

$.extend(jQuery.easing,{customEaseOutElastic:customEaseOutElastic});

function springAnimationHack(start, end, time, callback) {
  $(".springAnimationHack").css("left", start);
  $(".springAnimationHack").animate({
    left:  end
  }, {
    duration: time,
    easing: "customEaseOutElastic",
    step: function(currentLeft) {
        callback(currentLeft);
    },
    complete: function() {
      animationFinished = true
    }
  }) 
}

$('.center-floating-container').click(function (e) {
    $(this).hide();
    $(document.elementFromPoint(e.clientX, e.clientY)).trigger("click");
    $(this).show();
});

animationFinished = true
$( window ).resize(function() {
  if (animationFinished === true) {
    setMaskAngle(230);
  }
});