// Bundle and include the styles.
require('lity/dist/lity.css');
require('./sass/style.scss');

console.log("Well aren't you nosey… No need to inspect but do visit on your phone…");

require('lity');
require('./inlineSVG');
require('./animation');
require('./rotation');

const { isiOS, hide, show } = require('./utils');

if (isiOS()) {
  
  const metaElement = document.querySelector('.meta-marker');
  const elements = [metaElement];

  elements.forEach((el) => {
    const elClone = el;
    elClone.textContent = 'BLACKBOX://META';
    elClone.href = 'blackbox://meta';
    elClone.target = '_top';
    elClone.style.textDecoration = 'underline';
    elClone.onclick = () => {

      /* Deep link URL for existing users with app already installed on their device */
      setTimeout(() => {
        window.location = 'blackbox://meta';
      }, 0);

      /* Download URL for users without the app */
      setTimeout(() => {
        window.location = 'https://apps.apple.com/app/apple-store/id962969578?pt=607360&ct=BlackboxPuzzles.com&mt=8';
      }, 3000);

      return false;
    };
  });
}
