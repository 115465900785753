const iDevices = ['iPad', 'iPhone', 'iPod'];

function isiOS() {
  if (navigator.platform) {
    while (iDevices.length) {
      if (navigator.platform.indexOf(iDevices.pop()) !== -1) {
        return true;
      }
    }
  }

  return false;
}

function hide(selector) {
  document.querySelector(selector).style.visibility = 'hidden';
}

function show(selector) {
  document.querySelector(selector).style.visibility = 'visible';
}

module.exports = {
  isiOS,
  hide,
  show,
};
