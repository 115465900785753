function animate(selector, className, delay, removeClass) {
  if (delay === undefined) {

    var animationElements = document.querySelectorAll(selector), i;

    for (i = 0; i < animationElements.length; ++i) {

      var el = animationElements[i];

      if (removeClass) {
        el.classList.remove(className);
      } else {
        el.classList.add(className);
      }
    };
  } else {
    window.setTimeout(() => {
      animate(selector, className, undefined, removeClass);
    }, delay);
  }
}



// Animate logo etc. on load
animate('.animated', 'animate');

// Animate byline, app store link, etc.
animate('.animated-with-delay-1', 'animate', 1000);

// Fade in the footer
const footerFadeInDelay = 1500;
const footerAnimationDuration = 1000;

animate('.footer', 'color-in', footerFadeInDelay);
animate('.footer', 'transition', footerFadeInDelay + footerAnimationDuration, true);
